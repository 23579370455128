<template>
  <div class="login-form">
    <h2 class="title text-left text-uppercase">Login</h2>
    <h5 class="title">
      <em>Login with Email</em>
    </h5>
    <form @submit.prevent="handleSubmit" novalidate>
      <div v-if="isErrorMsg" class="form-group alert errorMsg">
        {{ info }}
      </div>
      <div v-if="errors" class="form-group alert errorMsg">
        <ul class="errors">
          <li v-for="error in validationErrors" :key="error" class="errorMsg">
            {{ error }}
          </li>
        </ul>
      </div>
      <div class="form-group">
        <input
          v-model="form.email"
          type="email"
          name="email"
          placeholder="Email"
          class="form-control login-form-control"
          :class="{ 'form-group--error': $v.form.email.$error }"
        />
      </div>
      <div
        class="form-group"
        :class="{ 'form-group--error': $v.form.password.$error }"
      >
        <input
          v-model="form.password"
          type="password"
          placeholder="Password"
          name="password"
          class="form-control login-form-control"
        />
      </div>
      <div class="text-center">
        <button class="btn btn-ghost login-button text-uppercase" type="submit">
          Login
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import { required, sameAs, email } from "vuelidate/lib/validators";
// Token JS tools
import * as TokenTools from "@/utils/TokenTools.js";
export default {
  name: "RegisterForm",
  components: {},
  data() {
    return {
      isErrorMsg: false,
      info: "",
      validationErrors: [],
      errors: false,
      loading: false,
      form: {
        // name: null,
        email: "",
        password: "",
        // confirmPassword: null,
      },
    };
  },
  validations: {
    form: {
      /* name: {
        required
      },*/
      email: {
        required,
        email,
      },
      password: {
        required,
      },
      /* confirmPassword: {
        required,
        sameAsPassword: sameAs('password')
      }*/
    },
  },
  mounted() {
    if (this.$route.query && this.$route.query.registration) {
      this.isErrorMsg = true;
      if (this.$route.query.registration === "1") {
        this.info =
          "Registration successful. Administrator approval is required.  A problem occurred sending registration email.  Please consult an administrator for approval.";
      } else if (this.$route.query.registration === "2") {
        this.info =
          "Registration successful. Administrator approval is required.  You will receive an email confirmation when your registration has been approved.";
      } else if (this.$route.query.registration === "3") {
        this.info = "A user with this email address already exists";
      }
    }
  },
  methods: {
    handleSubmit() {
      this.errors = this.$v.$invalid;
      this.$v.$touch();
      this.composeErrors();

      if (!this.errors) {
        this.loading = true;

        this.$store.dispatch("user/login", this.form).then((response) => {
          this.responseAction(response.data);
        });
      }
    },
    responseAction(data) {
      this.loading = false;
      if (data.error !== undefined) {
        this.isErrorMsg = true;
        this.info = data.error;
      } else {
        this.isErrorMsg = false;
        this.info = "";
        TokenTools.setToken(data);
        this.$router.push({ name: "maps" });
      }
    },

    composeErrors() {
      var errorList = [];

      var emailExists = this.$v.form.email.required;
      var passwordExists = this.$v.form.password.required;

      if (!emailExists && !passwordExists) {
        errorList.push("Please enter all fields");
      } else {
        if (!emailExists) {
          errorList.push("Please enter your email");
        } else if (this.$v.form.email.$invalid) {
          errorList.push("Email address invalid");
        }

        if (!passwordExists) {
          errorList.push("Please enter your password");
        }
      }
      this.validationErrors = errorList;
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_auth.scss";
.title {
  color: #f8f9fa;
}
div.errorMsg {
  color: #856404;
  background-color: #fff3cd;
}

li.errorMsg {
  margin-bottom: 0rem;
}

ul.errors {
  margin-bottom: 0rem;
  padding-inline-start: 20px;
}
.form-control.login-form-control {
  border-radius: 4px;
  border: 1px solid hsla(180, 0%, 70%, 0.8);
  background-color: #f8f9fa;
}
.login-button {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: white;
  font-weight: 700;
  border-radius: 4px;
  border: 1px solid hsla(180, 0%, 70%, 0.8);
  background-color: hsla(139, 35%, 65%, 1);
  width: 200px;
}
</style>
