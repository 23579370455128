<template>
  <div class="register-form">
    <h2 class="title text-left text-uppercase">Register</h2>
    <h5 class="title">
      <em>Sign up with Email</em>
    </h5>
    <form @submit.prevent="handleSubmit" novalidate>
      <div v-if="responseErrors[0]" class="errors form-group alert errorMsg">
        <ul class="errors">
          <li
            v-for="(error, index) in responseErrors"
            :key="index"
            class="response-error"
          >
            {{ error }}
          </li>
        </ul>
      </div>
      <div v-if="errors" class="form-group alert errorMsg">
        <ul class="errors">
          <li v-for="error in validationErrors" :key="error" class="errorMsg">
            {{ error }}
          </li>
        </ul>
      </div>
      <div
        class="form-group"
        :class="{ 'form-group--error': $v.form.name.$error && errors }"
      >
        <input
          v-model="form.name"
          type="text"
          name="name"
          placeholder="Name"
          class="form-control register-form-control"
        />
      </div>
      <div
        class="form-group"
        :class="{ 'form-group--error': $v.form.email.$error && errors }"
      >
        <input
          v-model="form.email"
          type="email"
          name="email"
          placeholder="Email"
          class="form-control register-form-control"
        />
      </div>
      <div
        class="form-group"
        :class="{ 'form-group--error': $v.form.company.$error && errors }"
      >
        <input
          v-model="form.company"
          type="text"
          name="company"
          placeholder="Company"
          class="form-control register-form-control"
        />
      </div>
      <div
        class="form-group"
        :class="{ 'form-group--error': $v.form.phone.$error && errors }"
      >
        <input
          v-model="form.phone"
          type="text"
          name="phone"
          placeholder="Phone"
          class="form-control register-form-control"
        />
      </div>
      <div
        class="form-group"
        :class="{ 'form-group--error': $v.form.password.$error && errors }"
      >
        <input
          v-model="form.password"
          type="password"
          placeholder="Password"
          name="password"
          class="form-control register-form-control"
        />
      </div>
      <div
        class="form-group"
        :class="{
          'form-group--error': $v.form.confirmPassword.$error && errors,
        }"
      >
        <input
          v-model="form.confirmPassword"
          type="password"
          name="confirmpassword"
          placeholder="Confirm Password"
          class="form-control register-form-control"
        />
      </div>
      <div class="text-center mt-4">
        <button
          class="btn btn-ghost register-button text-uppercase"
          type="submit"
        >
          Create An Account
        </button>
      </div>
    </form>
    <div v-if="success" class="success mt-4">
      <h5>{{ successMessage }}</h5>
    </div>
  </div>
</template>
<script>
import { required, sameAs, email, helpers } from "vuelidate/lib/validators";
const phoneNumber = helpers.regex(
  "phoneNumber",
  /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g
);
export default {
  name: "RegisterForm",
  components: {},
  data() {
    return {
      errors: false,
      responseErrors: [],
      validationErrors: [],
      loading: false,
      success: false,
      successMessage: null,
      form: {
        name: null,
        email: null,
        password: null,
        confirmPassword: null,
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
      },
      company: {
        required,
      },
      phone: {
        required /*,
        phoneNumber
        */,
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  methods: {
    handleSubmit() {
      this.responseErrors = [];
      this.errors = this.$v.$invalid;
      this.$v.$touch();
      this.composeErrors();
      if (!this.errors) {
        this.loading = true;
        this.$http
          .post("/auth/register", this.form)
          .then(({ data }) => {
            this.resetForm();
            this.loading = false;
            this.errors = null;
            if (data.error) {
              this.responseErrors.push(data.error);
            } else {
              //set token, redirect
              this.success = true;
              this.successMessage = data.message;
            }
          })
          .catch((err) => {
            this.responseErrors = [];
            this.loading = false;
          });
      }
    },
    resetForm() {
      this.form = {
        name: null,
        email: null,
        password: null,
        confirmPassword: null,
      };
    },
    composeErrors() {
      var errorList = [];

      var fields = [
        "name",
        "email",
        "company",
        "password",
        "phone",
        "confirmPassword",
      ];

      var missingFields = [];
      for (var field of fields) {
        if (!this.$v.form[field].required) {
          missingFields.push(field);
        }
      }
      // if there are two or more missing fields, simply state: "enter all fields"
      // otherwise, state the specific field to enter
      if (missingFields.length >= 2) {
        errorList.push("Please enter all fields");
      } else if (missingFields.length !== 0) {
        if (missingFields[0] === "confirmPassword") {
          errorList.push("Please confirm your password");
        } else {
          errorList.push(`Please enter your ${missingFields[0]}`);
        }
      }

      // Data is valid

      // vaild email
      if (this.$v.form.email.required && this.$v.form.email.$invalid) {
        errorList.push("Email address invalid");
      }

      // valid phone number (essentailly only containing plusses spaces and numbers)
      if (this.$v.form.phone.required && this.$v.form.phone.$invalid) {
        errorList.push("Phone number invalid");
      }

      // confirmPassword matches
      if (
        this.$v.form.confirmPassword.required &&
        this.$v.form.confirmPassword.$invalid
      ) {
        errorList.push("Passwords do not match");
      }

      this.validationErrors = errorList;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_auth.scss";
.title {
  color: #f8f9fa;
}
div.errorMsg {
  color: #856404;
  background-color: #fff3cd;
}

li.errorMsg {
  margin-bottom: 0rem;
}

ul.errors {
  margin-bottom: 0rem;
  padding-inline-start: 20px;
}
.form-control.register-form-control {
  border-radius: 4px;
  border: 1px solid hsla(180, 0%, 70%, 0.8);
  background-color: #f8f9fa;
}
.register-button {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: white;
  font-weight: 700;
  border-radius: 4px;
  border: 1px solid hsla(180, 0%, 70%, 0.8);
  background-color: hsla(139, 35%, 65%, 1);
  width: 200px;
}
</style>
