<template>
  <div class="register position-relative">
    <div class="content-wrapper">
      <div class="banner-container">
        <div class="d-none d-xl-block">
          <a href="https://brickworksllc.co">
            <img
              class="bwx-logo-xl"
              :src="
                require('@/assets/brand/brickworks-skyfall-horizontal-dark.svg')
              "
              title="brickworks_logo_grey"
              alt="brickworks_logo_grey"
            />
          </a>
        </div>
        <div class="d-none d-lg-block d-xl-none">
          <a href="https://brickworksllc.co">
            <img
              class="bwx-logo-lg"
              :src="
                require('@/assets/brand/brickworks-skyfall-horizontal-dark.svg')
              "
              title="brickworks_logo_grey"
              alt="brickworks_logo_grey"
            />
          </a>
        </div>
        <div class="d-none d-md-block d-lg-none">
          <a href="https://brickworksllc.co">
            <img
              class="bwx-logo-md"
              :src="
                require('@/assets/brand/brickworks-skyfall-horizontal-dark.svg')
              "
              title="brickworks_logo_grey"
              alt="brickworks_logo_grey"
            />
          </a>
        </div>
        <div class="d-none d-sm-block d-md-none">
          <a href="https://brickworksllc.co">
            <img
              class="bwx-logo-sm"
              :src="
                require('@/assets/brand/brickworks-skyfall-horizontal-dark.svg')
              "
              title="brickworks_logo_grey"
              alt="brickworks_logo_grey"
            />
          </a>
        </div>
        <div class="d-block d-sm-none">
          <a href="https://brickworksllc.co">
            <img
              class="bwx-logo-xs"
              :src="
                require('@/assets/brand/brickworks-skyfall-horizontal-dark.svg')
              "
              title="brickworks_logo_grey"
              alt="brickworks_logo_grey"
            />
          </a>
        </div>
      </div>
      <div class="top-padding"></div>
      <div class="register-container row">
        <div class="left col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <div class="inner">
            <login-form />
          </div>
        </div>
        <div class="right col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <div class="inner">
            <register-form />
          </div>
        </div>
      </div>
      <div class="bottom-padding"></div>
    </div>
  </div>
</template>
<script>
import RegisterForm from "@/components/auth/RegisterForm";
import LoginForm from "@/components/auth/LoginForm";
export default {
  name: "Register",
  components: {
    LoginForm,
    RegisterForm,
  },
};
</script>
<style lang="scss" scoped>
// @import '@/assets/scss/_auth';

h2.title {
  padding-top: 20px;
  color: #f8f9fa;
}
.register {
  width: 100%;
  height: 100vh;
  vertical-align: top;
  background-color: #434e65;
  background-image: url("~@/assets/brand/brickworks-contours.svg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.banner-container {
  width: 100%;
  padding: 0px;
  text-align: center;
}
.register-container {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  justify-content: center;
}
div.content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.left,
.right {
  background-color: none;
  width: 50vw;
  .inner {
    margin: 0 0;
    width: 100%;
  }
}

.top-padding {
  flex-grow: 1;
}

.bottom-padding {
  flex-grow: 3;
}

.left.col-xl-6,
.left.col-lg-6,
.left.col-md-6,
col-sm-12,
col-12 {
  padding-left: 25px;
  max-width: 500px;
}

.right.col-xl-6,
.right.col-lg-6,
.right.col-md-6 {
  padding-right: 25px;
  max-width: 500px;
}
.left.col-sm-12,
.left.col-12,
.right.col-sm-12,
.right.col-12 {
  padding-left: 25px;
  padding-right: 25px;
  max-width: 500px;
}
</style>

<style>
.bwx-logo-xl,
.bwx-logo-lg,
.bwx-logo-md,
.bwx-logo-sm,
.bwx-logo-xs {
  padding: 20px;
  align-self: center;
  max-width: 600px;
}
.bwx-logo-xl {
  width: 40%;
}

.bwx-logo-lg {
  width: 40%;
}

.bwx-logo-md {
  width: 40%;
}

.bwx-logo-sm {
  width: 40%;
}

.bwx-logo-xs {
  width: 40%;
}
</style>
